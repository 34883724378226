import React from 'react';
import SEO from 'components/seo';
import Layout from 'components/layout';
import Banner from 'sections/banner';
import Services from 'sections/services';
import CMS from 'sections/cms';
import SEOPlatform from 'sections/seo';
import POS from 'sections/pos';

import WorkHard from 'sections/work-hard';
import UltimateFeatures from 'sections/ultimate-features';
import HappyCustomer from 'sections/happy-customer';
import WorkFlow from 'sections/workflow';
import SubscribeUs from 'sections/subscribe-us';

export default function IndexPage() {
  return (
    <Layout>
      <SEO
        title="AinIntel – Grow your footprint, build your business"
        description="Our niche and targeted solutions will power your business and make you grow in every area"
      />
      <Banner />
      <Services />
      <POS />
      <SEOPlatform />

      <CMS />
      {/* <WorkHard /> */}
      {/* <UltimateFeatures /> */}
      <WorkFlow />
      <HappyCustomer />
      <SubscribeUs />
    </Layout>
  );
}
