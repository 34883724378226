/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Container, Grid, Box, Heading, Text } from 'theme-ui';
import SectionHeader from 'components/section-header';

const data = [
  {
    id: 1,
    title: 'Connect with the experts',
    text:
      'Our initial assessment discussions are totally free. Just speak to us and tell what your current challenges are and learn about what the industry does.',
  },
  {
    id: 2,
    title: 'Explain your goals',
    text:
      'We will chart out your journey to represent and better understand your goals. Set targets, goals and expectations.',
  },
  {
    id: 3,
    title: 'Sit back & see your business grow',
    text:
      'We create and implement solutions. We would like your support and feedback, but the solutions will take your business where you always wanted it to be.',
  },
];

export default function WorkFlow() {
  return (
    <Box as="section" variant="section.happyCustomer">
      <Container>
        <Heading sx={styles.title}>
            Let’s meet how its work
        </Heading>
        <Text as="p" sx={styles.summary}>
          We focus on the real problem. Speak with us, tell us your challenges and we will start creating ideas and solutions for you..
        </Text>

        <Grid sx={styles.grid}>
          {data.map((item) => (
            <Box sx={styles.card} key={item.id}>
              <Box sx={styles.iconBox}>{`0${item.id}`}</Box>
              <Box sx={styles.wrapper}>
                <Heading sx={{ variant: 'text.heading' }}>{item.title}</Heading>
                <Text sx={styles.wrapper.description}>{item.text}</Text>
              </Box>
            </Box>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

const styles = {
  section: {},

  grid: {
    pt: [0, null, null, null, null, null, null, null, 3],
    pb: [0, null, null, null, null, null, null, null, 5],
    mb: -1,
    gridGap: [
      '40px',
      '45px',
      '45px 30px',
      null,
      '60px 30px',
      '50px 40px',
      null,
      '75px',
    ],
    gridTemplateColumns: [
      'repeat(1,1fr)',
      null,
      'repeat(2,1fr)',
      null,
      'repeat(3,1fr)',
    ],
  },
  card: {
    display: 'flex',
    flexDirection: ['column', null, null, 'row'],
    textAlign: ['center', null, 'left'],
    px: [0, 5, 0],
  },

  iconBox: {
    flexShrink: 0,
    mb: [4, null, null, 0],
    fontSize: '26px',
    fontWeight: 300,
    color: 'text',
    lineHeight: 1,
    letterSpacing: 'heading',
    mr: ['auto', null, 0, 4, null, null, null, 5],
    ml: ['auto', null, 0],
    mt: [0, null, null, -1],
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    mt: '-5px',
    description: {
      fontSize: [1, 2],
      fontWeight: 'body',
      lineHeight: 1.9,
      pt: 2,
    },
  },
  title: {
    color: 'heading',
    fontWeight: 600,
    fontSize: ['28px', '32px', '32px', '32px', '32px', '32px', '40px'],
    lineHeight: 1.53,
    letterSpacing: ['-0.5px', null, null, '-1px'],
    mb: '20px',
    textAlign: 'center',
    span: {
      backgroundSize: 'cover',
      px: 2,
    },
  },
  summary: {
    color: 'textSecondary',
    fontSize: [1, null, null, 2, '15px', 2],
    lineHeight: [1.86, null, null, null, 1.86, 2.25],
    m: '20px auto 60px',
    maxWidth: '650px',
    textAlign: 'center',
  },
};
