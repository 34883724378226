/** @jsx jsx */
import { jsx, Box, Container, Button, Flex, Checkbox, Label } from 'theme-ui';
import { useState } from 'react';
import { rgba } from 'polished';
import SectionHeading from 'components/section-heading';
import Input from 'components/input';
import illustration from 'assets/images/subscribe-bg.png';

const SubscribeUs = () => {
  const [checked, setChecked] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('submitted.');
  };

  const handleCheckbox = () => {
    setChecked(!checked);
  };

  return (
    <Box as="section" sx={styles.section} variant="section.subscribe">
      <Container>
        <Box sx={styles.contentWrapper}>
          <SectionHeading
            sx={styles.heading}
            title="Want to talk to our Support team?"
            description="We are open about strategic alliances. If you already have a business plan and need our technology support, then lets grow together."
          />
          <Box sx={styles.subscribe}>
            <Button variant="primary">Contact us </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default SubscribeUs;

const styles = {
  section: {
    background: `url(${illustration}) no-repeat center bottom / cover`,
  },
  contentWrapper: {
    borderRadius: 15,
    p: [30, 40, null, '40px 100px', '50px 80px', '40px'],
    gap: '50px',
    display: ['block'],
    alignItems: 'center',
    gridTemplateColumns: ['repeat(2, 1fr)'],
  },
  heading: {
    textAlign: ['center', 'center', 'center', 'center', 'center'],
    mb: ['30px', '30px', '30px', '30px', 0],
    ml: 0,
    h2: {
      fontSize: ['24px', '24px', '24px', '28px', '32px', '36px'],
      letterSpacing: [0, 0, 0, '-1px'],
    },
    p: {
      lineHeight: 1.87,
      maxWidth: 420,
      textAlign: 'center',
      margin: '0 auto',
      marginTop: '25px',
    },
  },
  subscribe: {
    mt: '25px',
    textAlign: 'center',
    button: {
      minHeight: '45px',
      minWidth: ['100%', null, null, 'none', 200],
      fontSize: ['14px', '14px', '16px'],  
    }
  },
};
