/** @jsx jsx */
import { jsx, Box, Container } from 'theme-ui';
import SectionHeading from 'components/section-heading';
import Feature from 'components/cards/feature';

import Marketplace from 'assets/images/bar-chart-outline.png';
import SEO from 'assets/images/snow-outline.png';
import Application from 'assets/images/construct-outline.png';


const data = [
  {
    id: 3,
    icon: Application,
    title: 'Application Development',
    description: `We also offer end-to-end custom software development. Your vision, our execution. Ideal for full cycle MVP design and development.`,
  },
  {
    id: 2,
    icon: SEO,
    title: 'Search Engine Optimization',
    description: `Our proven SEO ideas and techniques help you rank higher , drive qualified traffic to your website, and run high-impact SEO campaign.`,
  },
  {
    id: 1,
    icon: Marketplace,
    title: 'CRM Solutions',
    description: `Our customized solutions make use of enhanced CRM platforms with tool sets and integrations for marketing, sales and customer service.`,
  },
];

const Services = () => {
  return (
    <Box as="section" id="services" variant="section.ultimateFeatures">
      <Container>
        <SectionHeading
          sx={styles.heading}
          title="The one-stop shop for all your business needs"
          description="We understand the businesses and your challenges. As soon as you speak with us, we will start planning your success drivers. Our expertise spans across"
        />
        <Box sx={styles.features}>
          {data?.map((item) => (
            <Feature key={item.id} className="feature-item" data={item} />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Services;

const styles = {
  heading: {
    marginBottom: [40, 50, 60, 80],
    maxWidth: ['none', null, null, 565, null, 'none'],
    h2: {
      color: 'heading',
      fontSize: ['28px', '32px', '32px', '32px', '32px', '32px', '40px'],
    },
    P: {
      fontSize: ['16px', '16px', '16px', '16px', '14px', '16px'],
      maxWidth: [510],
      m: ['5px auto 0'],
    },
  },
  features: {
    gap: [30, 30, 60, 40, 30, 60],
    display: ['grid', 'grid'],
    gridTemplateColumns: [
      'repeat(1, 1fr)',
      'repeat(1, 1fr)',
      'repeat(1, 1fr)',
      'repeat(2, 1fr)',
      'repeat(3, 1fr)',
    ],
    '.feature-item': {
      display: ['block', 'block', 'flex', 'flex', 'block', 'flex'],
      textAlign: ['center', 'center', 'left', 'left', 'center', 'left'],
      maxWidth: [290, 260, 'none'],
      m: ['0 auto', '0 auto', 0],
      figure: {
        m: [
          '0 auto 15px',
          '0 auto 15px',
          '0 30px 0 0',
          '0 30px 0 0',
          '0 0 15px',
          '0 30px 0 0',
        ],
      },
      h4: {
        fontWeight: [700],
        mb: ['15px', '15px', '20px', '15px'],
      },
      p: {
        fontSize: ['14px', '14px', '16px', '16px', '14px', '16px'],
      },
    },
  },
};
