/** @jsx jsx */
import {
  jsx,
  Box,
  Container,
  Heading,
  Text,
  Label,
  Image as Img,
  Button,
} from 'theme-ui';
import { rgba } from 'polished';
import sectionImage from 'assets/images/banner-1.svg';

const Banner = () => {
  return (
    <section sx={styles.section}>
      <Box as="section" sx={styles.section}>
        <Container>
          <Box sx={styles.contentWrapper}>
            <Box sx={styles.content}>
              <Heading as="h1">
                Grow your footprint, build your business
              </Heading>
              <Text as="p">
                Our niche and targeted solutions will power your business and make you grow in every area.
              </Text>
              <Box sx={styles.subscribe}>
                <Button variant="primary">Get Started </Button>
              </Box>
              <Box sx={styles.subscribe}>
                <Text as="span">
                    Get started with free tools, or get more with our premium software.
                </Text>
              </Box>              
            </Box>
            <Box as="figure" sx={styles.illustration}>
              <Img src={sectionImage} width="740" height="558" alt="section image" />
            </Box>
          </Box>
        </Container>
      </Box>
    </section>
  );
};

export default Banner;

const styles = {
  contentWrapper: {
    display: ['block', null, null, null, 'grid', 'flex'],
    gridTemplateColumns: ['1fr 1fr', null, null, null, '0.9fr 1.1fr'],
    gap: [0, 0, 0, 0, 40],
    alignItems: 'center',
    minHeight: [null, null, '100vh', '70vh', '50vh', '100vh'],
    pt: ['100px', null, null, '130px', '25px', null, 0],
    textAlign: ['center', null, 'left'],
  },
  section: {
    backgroundColor: '#FFFCF7',
    pt: [14, null, null, null, null, 0],
    pb: [6, null, null, 7, 11, 0],
  },

  content: {
    maxWidth: [null, null, null, '75%', '100%'],
    margin: [null, null, null, '0 auto', 0],
    textAlign: [null, null, null, 'center', 'left'],
    h1: {
      color: 'textSecondary',
      fontFamily: 'Crimson Text, Serif',
      fontWeight: 600,
      fontSize: ['34px', '34px', '34px', '52px', '40px', '49px', '62px'],
      lineHeight: [1.26, 1.26, 1.11],
    },
    p: {
      maxWidth: [450, null, null, 'none', 450],
      fontSize: ['14px', null, '18px', null, '16px', '15px', '18px'],
      lineHeight: [1.87, 1.87, 2.33, 2.33, 2],
      mt: ['25px', null, null, null, 4],
    },
  },
  subscribe: {
    display: 'flex',
    alignItems: 'center',
    mt: ['30px'],
    input: {
      mr: ['15px'],
      minHeight: ['45px', null, null, 60, 50, null, 60],
    },
    button: {
      minHeight: ['45px', null, null, 60, 50, null, 60],
      minWidth: ['100%', null, null, 'none', 350],
      fontSize: ['14px', '14px', '16px'],
    },
    span: {
      minHeight: ['45px', null, null, 60, 50, null, 60],
      maxWidth: [350, null, null, 'none', 350],
      fontSize: ['14px', '14px', '16px'],
    },
  },
  sponsoredBy: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: ['center', null, null, null, 'unset'],
    mt: ['30px', '30px', '40px', '40px', '30px'],
    span: {
      color: rgba('#566272', 0.6),
      fontSize: ['14px', '16px', '16px'],
    },
  },
  logos: {
    display: 'flex',
    alignItems: 'center',
    figure: {
      ml: ['10px', '16px', '28px', '16px', '16px'],
    },
    img: {
      maxWidth: ['69px', '85px', '100%', '100%', '79px', '100px', '100%'],
    },
  },
  illustration: {
    ml: [0, 0, '30px', 0, 0],
    mt: ['50px', null, null, null, 0],
    minWidth: ['auto', null, null, null, null, '600px'],
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    '.gatsby-image-wrapper': {
      maxWidth: [null, null, null, 566, 'none'],
      m: [null, null, null, '0 auto', null],
    },
  },
};
