/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Box, Container, Grid, Image, Heading, Text, Button } from 'theme-ui';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import support from 'assets/images/pos.svg';
import { LearnMore } from 'components/link';

const list = [
  'Offline First',
  'Payments & Integrations',
  'Sales analytics',
  'Multi-store management',
];

const CustomerSupport = () => {
  return (
    <Box as="section" id="dev" sx={styles.section}>
      <Container>
        <Box sx={styles.grid}>
          <Box sx={styles.content}>
            <Text as="span" sx={styles.slogan}>
              Application Development
            </Text>
            <Heading sx={styles.title}>
              Point of Sale (POS) Software
            </Heading>
            <Text as="p" sx={styles.summary}>
              Software to drive your daily operations at your business. Never lose track of your finances, resources and supplies. 
              Keep your book-keeping and records transparent and legible.
            </Text>

            <Grid sx={styles.list} as="ul">
              {list.map((item, i) => (
                <Text as="li" key={i}>
                  <IoIosCheckmarkCircle
                    sx={{ color: '#3CC68A', mr: 2 }}
                    size="20px"
                  />
                  {item}
                </Text>
              ))}
            </Grid>
            
            <Box sx={styles.learnMore}>
              <Button variant="primary">Learn More </Button>
            </Box>
          </Box>
          <Box sx={styles.illustration}>
            <Image  src={support} alt="support" />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default CustomerSupport;

const styles = {
  section: {
    padding: '70px 0',
    background: '#FFFCF7',
  },
  grid: {
    gap: [null, null, null, null, '0 10px'],
    display: ['flex', null, null, 'grid'],
    flexDirection: ['column-reverse', null, null, 'unset'],
    alignItems: 'center',
    gridTemplateColumns: [
      '1fr',
      null,
      null,
      '1fr',
      '470px 1fr',
      '600px 1fr',
      '680px 1fr',
    ],
  },
  illustration: {
    textAlign: 'center',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    mt: [0, 0, 0, 30, 0, 0],
    mb: [50, 50, 50, 0, 0, 0],
    maxWidth: ['100%', null, null, '80%', 'none'],
    ml: ['auto', null, null, 'auto', '0'],
    mr: ['auto', null, null, 'auto', '0'],
  },
  content: {
    maxWidth: [null, null, null, 560, 'none'],
    m: [null, null, null, '0 auto', 'unset'],
  },
  slogan: {
    color: '#00A99D',
    fontSize: '16px',
    textAlign: ['center', null, null, null, 'left'],
    fontWeight: 500,
    display: 'block',
  },
  title: {
    color: 'heading',
    fontWeight: 600,
    fontSize: ['28px', '32px', '32px', '32px', '32px', '32px', '40px'],
    lineHeight: 1.53,
    letterSpacing: ['-0.5px', null, null, '-1px'],
    mb: '20px',
    textAlign: ['center', null, null, null, 'left'],
    span: {
      backgroundSize: 'cover',
      px: 2,
    },
  },
  summary: {
    color: 'textSecondary',
    fontSize: [1, null, null, 2, '15px', 2],
    lineHeight: [1.86, null, null, null, 1.86, 2.25],
    maxWidth: 470,
    m: [null, null, null, '20px auto 0', '15px 0 0', null, 'unset'],
    textAlign: ['center', null, null, null, 'left'],
  },
  list: {
    gap: '0 18px',
    gridTemplateColumns: [null, null, null, 'repeat(2, 230px)'],
    justifyContent: [null, null, null, 'center', 'unset'],
    listStyle: 'none',
    mt: [4, null, null, 5, 4, 5],

    p: 0,
    li: {
      fontSize: [0, 1, null, 2, '15px', 2],
      fontWeight: 500,
      alignItems: 'center',
      color: 'textSecondary',
      display: 'flex',
      lineHeight: [2.81, null, null, null, 2.2, 2.81],
    },
  },
  learnMore: {
    textAlign: 'left',
    mt: '20px',

    button: {
      minHeight: '40px',
      minWidth: ['100%', null, null, 'none', 200],
      fontSize: ['14px', '14px', '16px'],  
    }
  },
};
